<template>
    <section class="tables new-updated-design">
        <vue-snotify></vue-snotify>
        <div class="row">
            <div class="col-xl-12">
                <div class="left-card m-b-30">
                    <div class="Customer-card-body">
                        <div v-if="regMsgTextErr != ''">
                            <div class="alert-message md-form">
                                <div class="alert alert-danger">{{ regMsgTextErr }}</div>
                            </div>
                        </div>
                        <div v-if="regMsgTextSucc != ''">
                            <div class="alert-message md-form">
                                <div class="alert alert-success">{{ regMsgTextSucc }}</div>
                            </div>
                        </div>
                        <div class="card" v-if="currStep == 1">
                            <div class="card-heading">
                                <h5>What is the Type of your accommodation?</h5>
                            </div>
                            <div class="card-body md-form">
                                <div class="form-group m-0">
                                    <div class="radio-buttons-wrapper">
                                        <div v-for="(text, value) in translatedPropertyTypeOptions" :key="value"
                                            class="form-check ">
                                            <input type="radio" :id="'propertyType_' + value" :value="value"
                                                v-model="propertyData.propertyType" class="form-check-input"
                                                name="propertyType">
                                            <label style="margin-left: 0px;" :for="'propertyType_' + value"
                                                class="form-check-label ">{{ text
                                                }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card second-step" v-if="currStep == 2">
                            <div class="card-heading">
                                <h5>What services does your accommodation offer to guests?</h5>
                            </div>
                            <div class="card-body md-form">
                                <div class="form-group m-0">
                                    <div class="radio-buttons-wrapper">
                                        <label class="form-label" for="wifiAvailable">{{ $t('property_detail.wifi')
                                            }}</label>
                                        <div v-for="(optionText, optionValue) in translatedyesnoOptions"
                                            :key="optionValue" class="form-check">
                                            <input type="radio" :id="'wifiAvailable_' + optionValue"
                                                :value="optionValue" v-model="propertyData.wifiAvailable"
                                                class="form-check-input">
                                            <label :for="'wifiAvailable_' + optionValue" class="form-check-label">{{
                                                optionText
                                            }}</label>
                                        </div>
                                    </div>
                                    <div class="radio-buttons-wrapper">
                                        <label class="form-label">{{ $t('property_detail.kitchen') }}</label>
                                        <div v-for="(optionText, optionValue) in translatedyesnoOptions"
                                            :key="optionValue" class="form-check">
                                            <input type="radio" :id="'kitchen_' + optionValue" :value="optionValue"
                                                v-model="propertyData.kitchen" class="form-check-input">
                                            <label :for="'kitchen_' + optionValue" class="form-check-label">{{
                                                optionText
                                                }}</label>
                                        </div>
                                    </div>
                                    <div class="radio-buttons-wrapper">
                                        <label class="form-label">{{ $t('property_detail.tv') }}</label>
                                        <div v-for="(optionText, optionValue) in translatedyesnoOptions"
                                            :key="optionValue" class="form-check">
                                            <input type="radio" :id="'tv_' + optionValue" :value="optionValue"
                                                v-model="propertyData.tv" class="form-check-input">
                                            <label :for="'tv_' + optionValue" class="form-check-label">{{ optionText
                                                }}</label>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="card-body md-form">
                                    <label for="regEmail">{{ $t('register.email') }} <span
                                            class="text-red">*</span></label>
                                    <input id="regEmail" name="regEmail" type="text" class="form-control"
                                        autocomplete="off" v-model="regEmail">
                                </div> -->
                            </div>
                        </div>
                        <div class="card" v-if="currStep == 3">
                            <div class="card-heading">
                                <h5>Accommodation details</h5>
                            </div>
                            <div class="card-body md-form">
                                <div class="form-group">
                                    <label for="max_guests">{{ $t('property_detail.Number_of_guests') }} <span
                                            class="text-red">*</span></label>
                                    <input type="number" id="max_guests" name="max_guests"
                                        :placeholder="$t('property_detail.Number_of_guests')" class="form-control"
                                        autocomplete="off" v-model="$v.propertyData.max_guests.$model" :maxlength="16"
                                        :state="$v.propertyData.max_guests.$dirty ? !$v.propertyData.max_guests.$error : null"
                                        aria-describedby="input-1-live-feedback">
                                </div>
                                <div class="form-group">
                                    <label for="num_bedrooms">{{ $t('property_detail.Number_of_bedrooms') }} <span
                                            class="text-red">*</span></label>
                                    <input type="number" id="num_bedrooms" name="num_bedrooms"
                                        :placeholder="$t('property_detail.Number_of_bedrooms')" class="form-control"
                                        autocomplete="off" v-model="$v.propertyData.num_bedrooms.$model"
                                        :state="$v.propertyData.num_bedrooms.$dirty ? !$v.propertyData.num_bedrooms.$error : null"
                                        aria-describedby="input-1-live-feedback">
                                </div>
                                <div class="form-group quality-fileds">
                                    <div class="guest-content">
                                        <label for="king-bed">
                                            {{ $t('property_detail.king_beds') }}
                                        </label>
                                        <div class="guest-col">
                                            <template>
                                                <div class="quantity">
                                                    <b-input-group>
                                                        <b-input-group-prepend>
                                                            <b-btn variant="info" @click="kingBedDecrement()">-</b-btn>
                                                        </b-input-group-prepend>

                                                        <b-form-input type="number" min="0.00"
                                                            v-model="propertyData.king_bed_quantity"
                                                            :value="propertyData.king_bed_quantity"></b-form-input>

                                                        <b-input-group-append>
                                                            <b-btn variant="info" @click="kingBedIncrement()">+</b-btn>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="guest-content">
                                        <label for="king-bed">
                                            {{ $t('property_detail.queen_beds') }}
                                        </label>
                                        <div class="guest-col">
                                            <template>
                                                <div class="quantity">
                                                    <b-input-group>
                                                        <b-input-group-prepend>
                                                            <b-btn variant="info" @click="queenBedDecrement()">-</b-btn>
                                                        </b-input-group-prepend>

                                                        <b-form-input type="number" min="0.00"
                                                            v-model="propertyData.queen_bed_quantity"
                                                            :value="propertyData.queen_bed_quantity"></b-form-input>

                                                        <b-input-group-append>
                                                            <b-btn variant="info" @click="queenBedIncrement()">+</b-btn>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="guest-content">
                                        <label for="king-bed">
                                            {{ $t('property_detail.sofa_beds') }}
                                        </label>
                                        <div class="guest-col">
                                            <template>
                                                <div class="quantity">
                                                    <b-input-group>
                                                        <b-input-group-prepend>
                                                            <b-btn variant="info" @click="sofaBedDecrement()">-</b-btn>
                                                        </b-input-group-prepend>

                                                        <b-form-input type="number" min="0.00"
                                                            v-model="propertyData.sofa_bed_quantity"
                                                            :value="propertyData.sofa_bed_quantity"></b-form-input>

                                                        <b-input-group-append>
                                                            <b-btn variant="info" @click="sofaBedIncrement()">+</b-btn>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="num_bathrooms">{{ $t('property_detail.Number_of_bathrooms') }} <span
                                            class="text-red">*</span></label>
                                    <input type="number" id="num_bathrooms" name="num_bathrooms"
                                        :placeholder="$t('property_detail.Number_of_bathrooms')" class="form-control"
                                        autocomplete="off" v-model="$v.propertyData.num_bathrooms.$model"
                                        :state="$v.propertyData.num_bathrooms.$dirty ? !$v.propertyData.num_bathrooms.$error : null"
                                        aria-describedby="input-1-live-feedback">
                                </div>
                                <!-- <div class="card-body md-form">
                                    <label for="regPhone">{{ $t('register.phone') }} <span class="text-red">*</span></label>
                                    <input id="regPhone" name="regPhone" maxlength="16" @input="enforcePhoneFormat()"
                                        type="number" placeholder="(123) 456-7890" class="form-control" autocomplete="off"
                                        v-model="regPhone">
                                </div> -->
                            </div>
                        </div>
                        <div class="card" v-if="currStep == 4">
                            <div class="card-heading">
                                <h5>Amenities details</h5>
                            </div>
                            <div class="card-body md-form">
                                <div class="form-group mb-4">
                                    <div class="checkbox-group">
                                        <label class="custom-label">{{ $t('property_detail.Bedroom_Amenities')
                                            }}</label>

                                        <div class="form-check">
                                            <input type="checkbox" id="King_Bed" name="King_Bed" value="King_Bed"
                                                v-model="propertyData.bedroom_Amenities" class="form-check-input">
                                            <label for="King_Bed" class="form-check-label">{{
                                                $t('property_detail.King_Bed')
                                            }}</label>
                                        </div>

                                        <div class="form-check">
                                            <input type="checkbox" id="Double_Sofa_Bed" name="Double_Sofa_Bed"
                                                value="Double_Sofa_Bed" v-model="propertyData.bedroom_Amenities"
                                                class="form-check-input">
                                            <label for="Double_Sofa_Bed" class="form-check-label">{{
                                                $t('property_detail.Double_Sofa_Bed') }}</label>
                                        </div>

                                        <div class="form-check">
                                            <input type="checkbox" id="Double_Futon" name="Double_Futon"
                                                value="Double_Futon" v-model="propertyData.bedroom_Amenities"
                                                class="form-check-input">
                                            <label for="Double_Futon" class="form-check-label">{{
                                                $t('property_detail.Double_Futon')
                                            }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group m-0">
                                    <div class="checkbox-group">
                                        <label class="custom-label">{{
                                            $t('property_detail.bathroom_amenities') }}:</label>

                                        <div class="form-check">
                                            <input type="checkbox" id="Soap" name="Soap" value="Soap"
                                                v-model="propertyData.bathroom_Amenities" class="form-check-input">
                                            <label for="Soap" class="form-check-label">{{ $t('property_detail.Soap')
                                                }}</label>
                                        </div>

                                        <div class="form-check">
                                            <input type="checkbox" id="Towels_provided" name="Towels_provided"
                                                value="Towels_provided" v-model="propertyData.bathroom_Amenities"
                                                class="form-check-input">
                                            <label for="Towels_provided" class="form-check-label">{{
                                                $t('property_detail.Towels_provided') }}</label>
                                        </div>

                                        <div class="form-check">
                                            <input type="checkbox" id="Toilet" name="Toilet" value="Toilet"
                                                v-model="propertyData.bathroom_Amenities" class="form-check-input">
                                            <label for="Toilet" class="form-check-label">{{ $t('property_detail.Toilet')
                                                }}</label>
                                        </div>

                                        <div class="form-check">
                                            <input type="checkbox" id="Shower_only" name="Shower_only"
                                                value="Shower_only" v-model="propertyData.bathroom_Amenities"
                                                class="form-check-input">
                                            <label for="Shower_only" class="form-check-label">{{
                                                $t('property_detail.Shower_only')
                                            }}</label>
                                        </div>

                                        <div class="form-check">
                                            <input type="checkbox" id="Shampoo" name="Shampoo" value="Shampoo"
                                                v-model="propertyData.bathroom_Amenities" class="form-check-input">
                                            <label for="Shampoo" class="form-check-label">{{
                                                $t('property_detail.Shampoo')
                                            }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card" v-if="currStep == 5">
                            <div class="card-heading">
                                <h5>Upload Images:</h5>
                            </div>
                            <div class="card-body md-form step-five">
                                <div class="form-group">
                                    <label for="images">{{ $t('property_detail.Upload_Images') }}</label>
                                    <input type="file" id="images" name="images[]" @change="previewImages" multiple
                                        accept="image/*">

                                    <div v-if="propertyData.imagePreviews.length > 0" class="images-listing">
                                        <div v-for="(imagePreview, index) in propertyData.imagePreviews" :key="index"
                                            class="images-card">
                                            <img v-if="imagePreview.path" :src="imagePreview.path" alt="Preview">
                                            <img v-else :src="imagePreview" alt="Preview">
                                            <button type="button" class="btn btn-primary" @click="removeImage(index)">
                                                <i class="fas fa-trash-alt"></i> {{ $t('property_detail.Remove') }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card" v-if="currStep == 6">
                            <div class="card-heading">
                                <h5>Description</h5>
                            </div>
                            <div class="card-body md-form">
                                <div class="form-group">
                                    <label for="description">{{ $t('property_detail.Description') }}</label>
                                    <textarea id="description" class="form-control" v-model="propertyData.description"
                                        :placeholder="$t('property_detail.Description')" rows="3"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="card" v-if="currStep == 7">
                            <div class="card-heading">
                                <h5>Price per night</h5>
                            </div>
                            <div class="card-body md-form">
                                <div class="form-group">
                                    <label for="price">{{ $t('property_detail.price') }}</label>
                                    <div>
                                        <input type="number" id="price" name="price" class="form-control mt-4"
                                            :placeholder="$t('property_detail.Price')" v-model="propertyData.price"
                                            :state="$v.propertyData.price.$dirty ? !$v.propertyData.price.$error : null"
                                            aria-describedby="input-1-live-feedback" required>
                                    </div>
                                    <div class="invalid-feedback" v-if="$v.propertyData.price.$error"
                                        id="input-1-live-feedback">
                                        This is a required field
                                    </div>
                                    <span v-if="propertyData.priceAmount" class="text-danger">{{
                                        propertyData.priceAmount
                                    }}</span>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <label for="price">Add Discount for specific days</label>
                                        <div class="form-check form-switch">
                                            <label class="switch">
                                                <input class="form-check-input" type="checkbox" id="discountToggle"
                                                    v-model="discountToggle">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <label for="price">Add discount on long stays</label>
                                        <div class="form-check form-switch">
                                            <label class="switch">
                                                <input class="form-check-input" type="checkbox" id="longstaysToggle"
                                                    v-model="longstaysToggle">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="card-body md-form mt-2" v-if="discountToggle">
                                        <div class="form-group">
                                            <div>
                                                <div>
                                                    <label for="discountPercentage">Discount Percentage</label>
                                                    <select id="discountPercentage" name="discountPercentage"
                                                        class="form-control" v-model="propertyData.discountpersentage">
                                                        <option value="" disabled selected>Select a discount percentage
                                                        </option>
                                                        <option v-for="percent in discountOptions" :key="percent"
                                                            :value="percent">
                                                            {{ percent }}%
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row mt-4">
                                                <div class="col-lg-6">
                                                    <label for="discount">Discount Start Date</label>
                                                    <div>
                                                        <input type="date" id="discountprice" name="discountprice"
                                                            class="form-control" placeholder="Discounted Price"
                                                            v-model="propertyData.discountsdate">
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <label for="discount">Discount End Date</label>
                                                    <div>
                                                        <input type="date" id="discountprice" name="discountprice"
                                                            class="form-control" placeholder="Discounted Price"
                                                            v-model="propertyData.discountedate">
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="card-body md-form mt-2" v-if="longstaysToggle">
                                        <div class="form-group">
                                            <div>
                                                <div class="row">
                                                    <div class="col-lg-6">
                                                        <div>
                                                            <label for="discountPercentage">One-week
                                                                stay</label>
                                                            <select id="discountPercentage" name="discountPercentage"
                                                                class="form-control" v-model="propertyData.oneweekdisc">
                                                                <option value="" disabled selected>Select a discount
                                                                    percentage
                                                                </option>
                                                                <option v-for="percent in discountOptions"
                                                                    :key="percent" :value="percent">
                                                                    {{ percent }}%
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class=" ">
                                                            <label for="discountPercentage">Two-week stay</label>
                                                            <select id="discountPercentage" name="discountPercentage"
                                                                class="form-control" v-model="propertyData.twoweekdisc">
                                                                <option value="" disabled selected>Select a discount
                                                                    percentage
                                                                </option>
                                                                <option v-for="percent in discountOptions"
                                                                    :key="percent" :value="percent">
                                                                    {{ percent }}%
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-6">
                                                        <div class="mt-4">
                                                            <label for="discountPercentage">Three-week stay</label>
                                                            <select id="discountPercentage" name="discountPercentage"
                                                                class="form-control"
                                                                v-model="propertyData.threeweekdisc">
                                                                <option value="" disabled selected>Select a discount
                                                                    percentage
                                                                </option>
                                                                <option v-for="percent in discountOptions"
                                                                    :key="percent" :value="percent">
                                                                    {{ percent }}%
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="mt-4">
                                                            <label for="discountPercentage">One-month stay</label>
                                                            <select id="discountPercentage" name="discountPercentage"
                                                                class="form-control" v-model="propertyData.onemonthdis">
                                                                <option value="" disabled selected>Select a discount
                                                                    percentage
                                                                </option>
                                                                <option v-for="percent in discountOptions"
                                                                    :key="percent" :value="percent">
                                                                    {{ percent }}%
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="card" v-if="currStep == 8">
                            <div class="card-heading">
                                <h5>House Rules</h5>
                            </div>
                            <div class="card-body md-form">
                                <div class="form-group">
                                    <label for="checkin_after" class="form-label">{{
                                        $t('property_detail.check_in_after') }}</label>
                                    <input type="time" id="checkin_after" name="checkin_after"
                                        placeholder="Check In After" v-model="$v.propertyData.checkin_after.$model"
                                        :class="{ 'is-invalid': $v.propertyData.checkin_after.$dirty && $v.propertyData.checkin_after.$error }"
                                        aria-describedby="checkinafter" class="form-control">
                                    <div v-if="$v.propertyData.checkin_after.$error" id="checkinafter"
                                        class="invalid-feedback">
                                        This is a required field
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="checkout_before" class="form-label">{{
                                        $t('property_detail.check_in_before') }}</label>
                                    <input type="time" id="checkout_before" name="checkout_before"
                                        :placeholder="$t('property_detail.Check_out_before')"
                                        v-model="$v.propertyData.checkout_before.$model"
                                        :class="{ 'is-invalid': $v.propertyData.checkout_before.$dirty && $v.propertyData.checkout_before.$error }"
                                        aria-describedby="checkoutbefore" class="form-control">
                                    <div v-if="$v.propertyData.checkout_before.$error" id="checkoutbefore"
                                        class="invalid-feedback">
                                        This is a required field
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="min_age" class="form-label">{{ $t('property_detail.min_age_to_rent')
                                        }}</label>
                                    <input type="number" id="min_age" name="min_age" placeholder="Minimum age to rent"
                                        v-model="$v.propertyData.min_age.$model"
                                        :class="{ 'is-invalid': $v.propertyData.min_age.$dirty && $v.propertyData.min_age.$error }"
                                        aria-describedby="minage" class="form-control">
                                    <div v-if="$v.propertyData.min_age.$error" id="minage" class="invalid-feedback">
                                        This is a required field
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="children" class="form-label">{{ $t('property_detail.children')
                                        }}</label>
                                    <select id="children" name="children" v-model="propertyData.children"
                                        :class="{ 'is-invalid': $v.propertyData.children.$dirty && $v.propertyData.children.$error }"
                                        class="form-control mb-3">
                                        <option value="" disabled>Select an option</option>
                                        <option v-for="option in childrens" :key="option" :value="option">
                                            {{ option }}
                                        </option>
                                    </select>
                                    <div v-if="$v.propertyData.children.$error" id="input-1-live-feedback"
                                        class="invalid-feedback">
                                        This is a required field.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="Events" class="form-label">{{ $t('property_detail.events') }}</label>
                                    <select id="Events" name="Events" v-model="propertyData.Events"
                                        :class="{ 'is-invalid': $v.propertyData.Events.$dirty && $v.propertyData.Events.$error }"
                                        class="form-control mb-3">
                                        <option value="" disabled>Select an option</option>
                                        <option v-for="option in Events" :key="option" :value="option">
                                            {{ option }}
                                        </option>
                                    </select>
                                    <div v-if="$v.propertyData.Events.$error" id="Events-feedback"
                                        class="invalid-feedback">
                                        This is a required field.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="Pets" class="form-label">{{ $t('property_detail.pets') }}</label>
                                    <select id="Pets" name="Pets" v-model="propertyData.Pets"
                                        :class="{ 'is-invalid': $v.propertyData.Pets.$dirty && $v.propertyData.Pets.$error }"
                                        class="form-control mb-3">
                                        <option value="" disabled>Select an option</option>
                                        <option v-for="option in Pets" :key="option" :value="option">
                                            {{ option }}
                                        </option>
                                    </select>
                                    <div v-if="$v.propertyData.Pets.$error" id="Pets-feedback" class="invalid-feedback">
                                        This is a required field.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="Smoking" class="form-label">{{ $t('property_detail.smoking') }}</label>
                                    <select id="Smoking" name="Smoking" v-model="propertyData.Smoking"
                                        :class="{ 'is-invalid': $v.propertyData.Smoking.$dirty && $v.propertyData.Smoking.$error }"
                                        class="form-control mb-3">
                                        <option value="" disabled>Select an option</option>
                                        <option v-for="option in Smoking" :key="option" :value="option">
                                            {{ option }}
                                        </option>
                                    </select>
                                    <div v-if="$v.propertyData.Smoking.$error" id="Smoking-feedback"
                                        class="invalid-feedback">
                                        This is a required field.
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="card" v-if="currStep == 9">
                            <div class="card-heading">
                                <h5>Where is the accommodation you want to add located?</h5>
                            </div>
                            <div class="card-body md-form">
                                <div class="form-group">
                                    <label for="city">{{ $t('properties_list.city') }}</label>
                                    <input type="text" id="city" name="city" class="form-control"
                                        :placeholder="$t('properties_list.city')" v-model="propertyData.city"
                                        :class="{ 'is-invalid': $v.propertyData.city.$error }"
                                        aria-describedby="input-1-live-feedback">
                                </div>
                                <div class="form-group" v-if="region == 'United States'">
                                    <label for="state">{{ $t('properties_list.state') }}</label>
                                    <input type="text" id="state" name="state" class="form-control"
                                        :placeholder="$t('properties_list.state')" v-model="propertyData.state"
                                        :class="{ 'is-invalid': $v.propertyData.state.$error }"
                                        aria-describedby="input-1-live-feedback">
                                </div>
                                <div class="form-group">
                                    <label for="country">{{ $t('property_detail.Country') }}</label>
                                    <input type="text-area" id="country" name="country" class="form-control"
                                        :placeholder="$t('property_detail.Country')" v-model="propertyData.country"
                                        :class="{ 'is-invalid': $v.propertyData.country.$error }"
                                        aria-describedby="input-1-live-feedback">
                                </div>
                                <div class="form-group">
                                    <label for="address">{{ $t('property_detail.address') }}</label>
                                    <input type="text-area" id="address" name="address" class="form-control"
                                        :placeholder="$t('property_detail.address')" v-model="propertyData.address"
                                        :class="{ 'is-invalid': $v.propertyData.address.$error }"
                                        aria-describedby="input-1-live-feedback">
                                </div>


                            </div>
                        </div>
                        <div v-if="currStep == 10">
                            <div class="card-heading">
                                <h5>Account Details</h5>
                            </div>
                            <div class="card-body md-form">
                                <div class="form-group">
                                    <label for="checkin_after" class="form-label">{{
                                        $t('property_detail.cardholder_name') }}</label>
                                    <input type="text" id="Cardholder's Name" name="Cardholder's Name"
                                        placeholder="Cardholder's Name" aria-describedby="checkinafter"
                                        class="form-control">
                                    <div v-if="$v.propertyData.checkin_after.$error" id="checkinafter"
                                        class="invalid-feedback">
                                        This is a required field
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="checkin_after" class="form-label">{{ $t('property_detail.card_number')
                                        }}</label>
                                    <input type="text" id="Card Number" name="Card Number" placeholder="Card Number"
                                        aria-describedby="checkinafter" class="form-control">
                                    <div v-if="$v.propertyData.checkin_after.$error" id="checkinafter"
                                        class="invalid-feedback">
                                        This is a required field
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label for="checkin_after" class="form-label">{{ $t('property_detail.cvv')
                                                }}</label>
                                            <input type="text" id="CVV" name="CVV" placeholder="CVV"
                                                aria-describedby="checkinafter" class="form-control">
                                            <div v-if="$v.propertyData.checkin_after.$error" id="checkinafter"
                                                class="invalid-feedback">
                                                This is a required field
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label for="checkin_after" class="form-label">{{
                                                $t('property_detail.expiry_date') }}</label>
                                            <input type="date" id="Exp Date" name="Exp Date" placeholder="Exp Date"
                                                aria-describedby="checkinafter" class="form-control">
                                            <div v-if="$v.propertyData.checkin_after.$error" id="checkinafter"
                                                class="invalid-feedback">
                                                This is a required field
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card laststep" v-if="currStep == 11">
                            <div class="card-heading">
                                <h5>{{ $t('property_detail.all_done') }}!</h5>
                            </div>
                            <div class="md-form">
                                <button type="button" class="btn btn-primary" @click="addProperty()"
                                    style="margin-left: 60%; ">{{
                                        $t('property_detail.list_property') }}</button>
                            </div>
                        </div>
                        <div class="card" v-if="currStep < 12">
                            <div class="footer-buttons md-form">
                                <button id="bckBtn" name="bckBtn" class="btn btn-default pull-left" v-if="currStep > 1"
                                    @click="bckBtn" :disabled="regBusy">
                                    <i class="fa fa-angle-left"></i> <span>{{ $t('register.back') }}</span>
                                </button>
                                <!-- <button id="bckBtn" name="bckBtn" class="btn btn-default pull-left" v-else
                                    @click="redirect('/')">
                                    <i class="fa fa-angle-left"></i>
                                     <span>{{ $t('register.home') }}</span>
                                </button> -->
                                <button id="regSub" name="regSub" class="btn btn-primary pull-right" @click="regSub"
                                    :disabled="regBusy" v-if="currStep !== 11">{{ regBtnText }}</button>
                            </div>
                        </div>
                        <div class="card" v-if="currStep == 12">
                            <div class="card-body md-form">
                                <button id="regSingIn" name="regSingIn" class="btn btn-primary pull-right"
                                    @click="regSingIn">{{
                                        $t('register.sign_in') }}</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div></div>
    </section>
</template>

<script>
import Vue from "vue";
import API from '@/api'
import Snotify, { SnotifyPosition } from 'vue-snotify'
import { validationMixin } from "vuelidate";
import { required, minLength, url, email, sameAs } from "vuelidate/lib/validators";

const options = {
    toast: {
        position: SnotifyPosition.rightTop,
    }
}
Vue.use(Snotify, options)
export default {
    // name: addProperty,
    mixins: [validationMixin],
    data() {
        return {
            currStep: 1,
            regBtnText: this.$t('register.next'),
            regBusy: false,
            regEmail: '',
            regPhone: '',
            regFname: '',
            type: 'password',
            regPassword: '',
            discountOptions: [5, 10, 15, 20, 25, 30, 50],
            discountToggle: false,
            longstaysToggle: false,
            btnText: 'fa fa-eye',
            regCpassword: '',
            regMsgTextErr: '',
            regMsgTextSucc: '',
            region: localStorage.getItem('region'),
            maxPropertyPrice: 99,
            bathroom_Amenities: {
                Soap: false,
                Towels_provided: false,
                Toilet: false,
                Shower_only: false,
                Shampoo: false,
            },
            bedroom_Amenities: {
                King_Bed: false,
                Double_Sofa_Bed: false,
                Double_Futon: false,
            },
            propertyData: {
                lang: "en",
                city: "",
                state: "",
                country: "",
                address: "",
                price: "",
                discountpersentage: "",
                oneweekdisc: "",
                twoweekdisc: "",
                threeweekdisc: "",
                onemonthdis: "",
                discountsdate: "",
                discountedate: "",
                priceAmount: "",
                max_guests: "",
                num_bedrooms: "",
                checkin_after: "",
                checkout_before: "",
                num_bathrooms: "",
                min_age: "",
                propertyType: "",
                children: "",
                Events: "",
                Pets: "",
                Smoking: "",
                wifiAvailable: "",
                kitchen: "",
                tv: "",
                king_bed_quantity: 0,
                queen_bed_quantity: 0,
                sofa_bed_quantity: 0,
                description: "",
                imagePreviews: [],
                bathroom_Amenities: [],
                bedroom_Amenities: [],
                id: "",
                currency: 'USD',
            },
            currencyOptions: [
                { value: 'USD', text: 'USD' },
                { value: 'EUR', text: 'Euro' },
                { value: 'GBP', text: 'Pound' },
                // Add more currencies as needed
            ],
            id: "",
            propertyTypeOptions: {
                'Entire Place': 'Entire Place',
                'Private Room': 'Private Room',
                'Shared Room': 'Shared Room',
                'Beachfront': 'Beachfront',
                'Mountain': 'Mountain',
                'Camping': 'Camping',
                'Lakefront': 'Lakefront',
                'Ski': 'Ski',
                'Desert': 'Desert',
                'Tropical': 'Tropical',
                'With_pool': 'With pool',
                'With_views': 'With views',
            },
            childrens: {
                'Not Allowd': 'Not Allowd',
                'Allowed: ages 0-13': 'Allowed: ages 0-13',
                'Allowed: ages 0-17': 'Allowed: ages 0-17',
            },
            Events: {
                'Not Allowd': 'Not Allowd',
                'Allowed: Family gatherings and Birthday parties': 'Allowed: Family gatherings and Birthday parties',
                'Allowed: Family gatherings': 'Allowed: Family gatherings',
                'Allowed: Birthday parties': 'Allowed:  Birthday parties',
            },
            Pets: {
                'Not Allowd': 'Not Allowd',
                'Allowed: cats less than 20 lbs per pet (limit 1 pets total)': 'Allowed: cats less than 20 lbs per pet (limit 1 pets total)',
                'Allowed: cats less than 20 lbs per pet (limit 2 pets total)': 'Allowed: cats less than 20 lbs per pet (limit 2 pets total)',
                'Allowed: cats less than 20 lbs per pet (limit 3 pets total)': 'Allowed: cats less than 20 lbs per pet (limit 3 pets total)',
            },
            Smoking: {
                'Not Allowd': 'Not Allowd',
                'Allowed': 'Allowed',
                'Allowed: in designated areas': 'Allowed: in designated areas',
            },
            yesNoOptions: {
                1: 'Yes',
                0: 'No'
            },
        };
    },
    validations: {
        propertyData: {

            num_bathrooms: {
                required,
            },
            max_guests: {
                required,
            },
            num_bedrooms: {
                required,
            },
            checkin_after: {
                required,

            },
            checkout_before: {
                required,
            },
            min_age: {
                required,
            },
            Events: {
                required,
            },
            Pets: {
                required,
            },
            Smoking: {
                required,
            },
            price: {
                required,
            },
            country: {
                required,
            },
            address: {
                required,
            },
            state: {
                required,
            },
            city: {
                required,
            },
            propertyType: {
                required,
            },
            children: {
                required,
            },
            wifiAvailable: {
                required,
            }, kitchen: {
                required,
            }, tv: {
                required,
            },
        }
    },

    methods: {
        getMaxPropertyPrice() {
            let currecny = localStorage.getItem('currency');
            API.getMaxPrice(currecny,
                data => {
                    let maxPrice = data.data.maxPrice
                    this.maxPropertyPrice = parseInt(maxPrice)
                    console.log(this.maxPropertyPrice, "Max property price")
                }, err => (
                    console.log(err)
                ))
        },
        kingBedIncrement() {
            this.propertyData.king_bed_quantity++
        },
        kingBedDecrement() {
            if (this.propertyData.king_bed_quantity > 0) {
                this.propertyData.king_bed_quantity--
            }
        },
        queenBedIncrement() {
            this.propertyData.queen_bed_quantity++
        },
        queenBedDecrement() {
            if (this.propertyData.queen_bed_quantity > 0) {
                this.propertyData.queen_bed_quantity--
            }
        },
        sofaBedIncrement() {
            this.propertyData.sofa_bed_quantity++
        },
        sofaBedDecrement() {
            if (this.propertyData.sofa_bed_quantity > 0) {
                this.propertyData.sofa_bed_quantity--
            }
        },
        showPassword() {
            if (this.type === 'password') {
                this.type = 'text'
                this.btnText = 'fa fa-eye-slash'
            } else {
                this.type = 'password'
                this.btnText = 'fa fa-eye'
            }
        },
        bckBtn() {
            if (this.currStep > 1) {
                this.regMsgTextErr = '';
                this.regBtnText = this.$t('register.next');
                this.currStep = this.currStep - 1;
            }
        },
        enforcePhoneFormat() {
            let x = this.regPhone
                .replace(/\D/g, "")
                .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

            this.regPhone = !x[2]
                ? x[1]
                : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
        },
        regSub() {
            this.regMsgTextErr = '';
            if (this.currStep === 1) {
                this.$v.propertyData.propertyType.$touch();

                if (!this.$v.propertyData.propertyType.required) {
                    this.regMsgTextErr = 'Please Select Type Of your Property';
                    return;
                }
                this.currStep = 2;
                this.regBtnText = this.$t('register.next');
            } else if (this.currStep === 2) {
                this.regBtnText = this.$t('register.next');

                if (!this.propertyData.wifiAvailable) {
                    this.regMsgTextErr = 'Please Select Any Option in Wifi';
                    return;
                }
                if (!this.propertyData.kitchen) {
                    this.regMsgTextErr = 'Please Select Any Option in Kitchen';
                    return;
                }
                if (!this.propertyData.tv) {
                    this.regMsgTextErr = 'Please Select Any Option in TV';
                    return;
                }
                this.currStep = 3;
                this.regBtnText = this.$t('register.next');

            } else if (this.currStep == 3) {
                this.regBtnText = this.$t('register.next');
                if (!this.propertyData.max_guests) {
                    this.regMsgTextErr = 'Please Add the Number Of Gusets';
                    return;
                }
                if (!this.propertyData.num_bedrooms) {
                    this.regMsgTextErr = 'Please Add the Number Of Bedrooms';
                    return;
                }
                if (!this.propertyData.num_bathrooms) {
                    this.regMsgTextErr = 'Please Add the Number Of Bathrooms';
                    return;
                }
                this.currStep = 4;
                this.regBtnText = this.$t('register.next');
            } else if (this.currStep == 4) {
                console.log(this.propertyData)
                this.currStep = 5;
                this.regBtnText = this.$t('register.next');
            } else if (this.currStep == 5) {
                if (!this.propertyData.imagePreviews.length) {
                    this.regMsgTextErr = 'Please Add the Images of Property';
                    return;
                }
                if (this.propertyData.imagePreviews.length < 4) {
                    this.regMsgTextErr = 'Please Add Atleasr 4 Images of Property';
                    return;
                }
                this.currStep = 6;
                this.regBtnText = this.$t('register.next');
            } else if (this.currStep == 6) {

                this.currStep = 7;
                this.regBtnText = this.$t('register.next');
            } else if (this.currStep == 7) {
                if (!this.propertyData.price) {
                    this.regMsgTextErr = 'Please Add the Price Per night';
                    return;
                }
                if (this.propertyData.price > this.maxPropertyPrice) {
                    this.propertyData.priceAmount = `Price must be ${this.maxPropertyPrice} or less`;
                    return;
                }
                this.currStep = 8;
                this.regBtnText = this.$t('register.next');
            } else if (this.currStep == 8) {
                if (!this.propertyData.checkin_after) {
                    this.regMsgTextErr = 'Please Add the Checkin TIme';
                    return;
                }
                if (!this.propertyData.checkout_before) {
                    this.regMsgTextErr = 'Please Add the Checkout TIme';
                    return;
                } if (!this.propertyData.min_age) {
                    this.regMsgTextErr = 'Please Add Min Age to rent';
                    return;
                } if (!this.propertyData.children) {
                    this.regMsgTextErr = 'Please Add Add information about childers';
                    return;
                }
                if (!this.propertyData.Events) {
                    this.regMsgTextErr = 'Please Add Add information about Events';
                    return;
                }
                if (!this.propertyData.Pets) {
                    this.regMsgTextErr = 'Please Add Add information about Pets';
                    return;
                }
                if (!this.propertyData.Smoking) {
                    this.regMsgTextErr = 'Please Add Add information about Smoking';
                    return;
                }
                if (this.propertyData.price > this.maxPropertyPrice) {
                    this.propertyData.priceAmount = `Price must be ${this.maxPrice} or less`;
                    return;
                }
                this.currStep = 9;
                this.regBtnText = this.$t('register.next');
            } else if (this.currStep == 9) {
                if (!this.propertyData.city) {
                    this.regMsgTextErr = 'Please Add the City';
                    return;
                }
                if (localStorage.getItem('region') == 'United States' && !this.propertyData.state) {
                    this.regMsgTextErr = 'Please Add the state';
                    return;
                }
                if (!this.propertyData.country) {
                    this.regMsgTextErr = 'Please Add the Country';
                    return;
                }
                if (!this.propertyData.address) {
                    this.regMsgTextErr = 'Please Add the Address';
                    return;
                }
                this.currStep = 10;
                this.regBtnText = this.$t('register.next');
            } else if (this.currStep == 10) {
                this.currStep = 11;
                this.regBtnText = this.$t('register.next');
            } else if (this.currStep == 11) {
                this.regBtnText = this.$t('register.next');
            }
        },

        previewImages(event) {
            const files = event.target.files;
            if (files) {
                for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    if (file) {
                        const reader = new FileReader();
                        reader.onload = (e) => {
                            this.propertyData.imagePreviews.push(e.target.result);
                            this.$forceUpdate();
                        };
                        reader.readAsDataURL(file);
                    }
                }
            }
        },
        removeImage(index) {
            this.propertyData.imagePreviews.splice(index, 1);
            this.$forceUpdate();
        },
        addProperty() {
            this.$v.propertyData.$touch();
            if (this.propertyData.id) {
                API.updateProperty(this.propertyData, data => {
                    if (data.status == 200) {
                        this.$router.push({ name: 'properties' });
                    } else {
                        this.$swal({
                            type: 'error',
                            title: 'Oops...',
                            text: data.message,
                        })
                    }
                    this.isDisabled = false;
                }, this.errorCB);
            } else {
                API.addProperty(this.propertyData, data => {
                    if (data.status == 200) {
                        if (this.$route.path == '/add_property') {
                            this.$router.push({ name: 'home' });
                        } else {
                            this.$router.push({ name: 'properties' });
                        }
                    } else {
                        this.$swal({
                            type: 'error',
                            title: 'Oops...',
                            text: data.message,
                        })
                    }
                    this.isDisabled = false;
                }, this.errorCB);
            }

        },
        editProperty() {
            if (this.$route.params.data) {
                this.propertyData = this.$route.params.data;
                const today = new Date();
                const discountDate = new Date(this.propertyData.discountedate);
                if (discountDate > today) {
                    this.discountToggle = true;
                }
                this.propertyData.imagePreviews = this.$route.params.data.photos;
                this.propertyData.propertyType = this.$route.params.data.property_type;
                this.propertyData.wifiAvailable = this.$route.params.data.wifi_available;
                this.propertyData.price = this.$route.params.data.price;
                this.propertyData.kitchen = this.$route.params.data.kitchen_available;
                this.propertyData.tv = this.$route.params.data.tv_available;
                this.propertyData.lang = this.$i18n.locale;
                this.propertyData.description = this.$route.params.data.property_description;
                if (typeof this.propertyData.bathroom_Amenities === 'string') {
                    this.propertyData.bathroom_Amenities = JSON.parse(this.propertyData.bathroom_Amenities);
                }
                if (Array.isArray(this.propertyData.bathroom_Amenities)) {
                    this.propertyData.bathroom_Amenities.forEach(amenity => {
                        if (amenity === "Soap") {
                            this.propertyData.soapAvailable = true;
                        } else if (amenity === "Shower_only") {
                            this.propertyData.showerOnly = true;
                        } else if (amenity === "Towels_provided") {
                            this.propertyData.towelsProvided = true;
                        } else if (amenity === "Shampoo") {
                            this.propertyData.shampooAvailable = true;
                        } else if (amenity === "Toilet") {
                            this.propertyData.toiletAvailable = true;
                        }
                    });
                }
                if (typeof this.propertyData.bedroom_Amenities === 'string') {
                    this.propertyData.bedroom_Amenities = JSON.parse(this.propertyData.bedroom_Amenities);
                }
                if (Array.isArray(this.propertyData.bedroom_Amenities)) {
                    this.propertyData.bedroom_Amenities.forEach(amenity => {
                        if (amenity === "King_Bed") {
                            this.propertyData.bedroom_Amenities.King_Bed = true;
                        } else if (amenity === "Double_Sofa_Bed") {
                            this.propertyData.bedroom_Amenities.Double_Sofa_Bed = true;
                        } else if (amenity === "Double_Futon") {
                            this.propertyData.bedroom_Amenities.Double_Futon = true;
                        }
                    });
                }
            }
        }

    }, computed: {

        // translatedstate: {
        //     get() {
        //         const lang = this.$i18n.locale;
        //         if (lang === 'en') {
        //             return this.propertyData.state;
        //         } else {
        //             return this.propertyData[`state_${lang}`];
        //         }
        //     },
        //     set(newValue) {
        //         const lang = this.$i18n.locale;
        //         if (lang === 'en') {
        //             this.propertyData.state = newValue;
        //         } else {
        //             this.propertyData[`state_${lang}`] = newValue;
        //         }
        //     }
        // },
        // translatedCity: {
        //     get() {
        //         const lang = this.$i18n.locale;
        //         if (lang === 'en') {
        //             return this.propertyData.city;
        //         } else {
        //             return this.propertyData[`city_${lang}`];
        //         }
        //     },
        //     set(newValue) {
        //         const lang = this.$i18n.locale;
        //         if (lang === 'en') {
        //             this.propertyData.city = newValue;
        //         } else {
        //             this.propertyData[`city_${lang}`] = newValue;
        //         }
        //     }
        // },
        // translatedcountry: {
        //     get() {
        //         const lang = this.$i18n.locale;
        //         if (lang === 'en') {
        //             return this.propertyData.country;
        //         } else {
        //             return this.propertyData[`country_${lang}`];
        //         }
        //     },
        //     set(newValue) {
        //         const lang = this.$i18n.locale;
        //         if (lang === 'en') {
        //             this.propertyData.country = newValue;
        //         } else {
        //             this.propertyData[`country_${lang}`] = newValue;
        //         }
        //     }
        // },
        // translatedDescription: {
        //     get() {
        //         const lang = this.$i18n.locale;
        //         if (lang === 'en') {
        //             return this.propertyData.property_description;
        //         } else {
        //             return this.propertyData[`property_description_${lang}`];
        //         }
        //     },
        //     set(newValue) {
        //         const lang = this.$i18n.locale;
        //         if (lang === 'en') {
        //             this.propertyData.property_description = newValue;
        //         } else {
        //             this.propertyData[`property_description_${lang}`] = newValue;
        //         }
        //     }
        // },
        translatedPropertyTypeOptions() {
            const translatedOptions = {};
            for (const key in this.propertyTypeOptions) {
                translatedOptions[key] = this.$t(`property_detail.${key}`);
            }
            return translatedOptions;
        },
        translatedyesnoOptions() {
            const translatedOptions = {};
            for (const key in this.yesNoOptions) {
                translatedOptions[key] = this.$t(`property_detail.${key}`);
            }
            return translatedOptions;
        }
    },
    mounted() {
        console.log('Hello World');
        this.getMaxPropertyPrice();
        this.editProperty();
    },
    watch: {
        // translatedcountrys(newValue) {
        //     this.propertyData.country = newValue;
        // },
        // translatedState(newValue) {
        //     this.propertyData.state = newValue;
        // },
        // translatedCity(newValue) {
        //     this.propertyData.city = newValue;
        // },
        // translatedDescription(newValue) {
        //     this.propertyData.property_description = newValue;
        // },
        '$i18n.locale': function (newLocale, oldLocale) {
            this.propertyData.lang = this.$i18n.locale;

        }
    }
}
</script>

<style></style>

<style>
.checkbox-group .custom-control-label {
    padding-top: 5px !important;
}

.custom-label {
    font-size: 0.875rem;
}

.checkbox-group .b-form-checkbox {
    display: inline-flex;
    align-items: center;
    margin-right: 10px;
    vertical-align: middle;
}

.Customer-card-body {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.Customer-card-body .card {
    border: 0;
}

.Customer-card-body .card .card-heading h5 {
    margin: 0;
    font-size: 1.4rem;
    line-height: 1.2;
}

.Customer-card-body .card-body {
    border: 1px solid #bcbcbf;
    border-radius: 10px;
    padding: 20px;
}

.Customer-card-body .card-body .form-group .form-check {
    margin-top: 0;
    margin-bottom: 15px;
    width: 50%;
}

.Customer-card-body .card-body .form-group .radio-buttons-wrapper {
    padding-left: 1.25rem;
    display: flex;
    flex-wrap: wrap;
}

.Customer-card-body .card .card-heading {
    margin-bottom: 15px;
}

.footer-buttons.md-form {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-buttons.md-form .btn.btn-default {
    border: 1px solid #70d3ff;
    background: #fff;
    width: 105px;
    margin-right: 15px;
    font-weight: 500;
    box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%) !important;
}

.footer-buttons.md-form .btn.btn-default:hover {
    background: #70d3ff;
    color: #fff;
}

.footer-buttons.md-form .btn.btn-primary {
    border-radius: 5px !important;
}

.Customer-card-body .card.second-step .card-body .form-group {
    margin: 0;
}

.Customer-card-body .card.second-step .card-body .form-group .radio-buttons-wrapper {
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.Customer-card-body .card.second-step .card-body .form-group .radio-buttons-wrapper label.form-check-label {
    margin-left: 0;
}

.Customer-card-body .card.second-step .card-body .form-group .radio-buttons-wrapper label.form-label {
    width: 100%;
    font-weight: 500;
}

.Customer-card-body .card.second-step .card-body .form-group .form-check {
    width: 70px;
    padding-left: 20px;
}

.Customer-card-body .card-body .form-group .form-control {
    border-radius: 35px;
}

.Customer-card-body .card-body .form-group .form-control {
    border-radius: 35px;
    border: 1px solid #676A7D;
}

.Customer-card-body .card-body .form-group .checkbox-group .form-check .form-check-label {
    margin: 0;
}

.Customer-card-body .card-body .form-group .checkbox-group .form-check {
    padding-left: 20px;
}

.Customer-card-body .card-body .form-group .checkbox-group .custom-label {
    font-weight: 500;
}

.Customer-card-body .card-body.step-five .form-group label {
    display: block;
    font-weight: 500;
    margin-bottom: 10px;
}

.Customer-card-body .card-body.step-five .form-group {
    margin: 0;
}

.images-listing {
    display: flex;
    flex-wrap: wrap;
    gap: 22px;
}

.images-listing .images-card {
    margin-top: 15px;
    width: 22.8%;
}

.images-listing .images-card img {
    width: 100%;
}

.images-listing .images-card button.btn {
    width: 100% !important;
    margin-top: 5px !important;
    border-radius: 5px !important;
    height: 30px;
    padding: 0 !important;
    font-size: 14px !important;
}

.Customer-card-body .card-body.step-five .form-group input[type=file] {
    font-size: 14px;
}

.Customer-card-body .card-body.step-five .form-group input[type=file]::file-selector-button {
    margin-right: 20px;
    border: none;
    background: #70d3ff;
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: background .2s ease-in-out;
}

.Customer-card-body .card-body .form-group textarea.form-control {
    padding: 10px;
    height: 104px;
    border-radius: 15px;
}

.Customer-card-body .card.laststep {
    border: 1px solid #bcbcbf;
    border-radius: 10px;
    padding: 30px 20px;
    text-align: center;
}

.Customer-card-body .card-body .form-group label {
    font-weight: 500;
}

.guest-content {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    margin-bottom: 15px;
    border-right: 0 !important;
}

.guest-content:last-child {
    margin-bottom: 0;
}

.guest-content .btn.btn-info {
    padding: 0;
    width: 30px;
    height: 30px;
    font-size: 24px;
    border-radius: 0;
    background: transparent;
    border: 1px solid rgb(192 194 196);
    color: #000;
    border-radius: 100px;
}

.guest-content .form-control {
    padding: 0;
    height: 30px;
    border: 0;
    text-align: center;
    width: 60px;
    appearance: none;
}

.guest-content .form-control::-webkit-outer-spin-button,
.guest-content .form-control::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.form-group.quality-fileds {
    display: flex;
    align-items: center;
    gap: 25px;
}

.form-group.quality-fileds .guest-content {
    margin: 0;
    gap: 10px;
}

.form-group.quality-fileds .guest-content .input-group {
    gap: 3px;
}

.form-group.quality-fileds .guest-content label {
    margin: 00;
}
</style>


<style>
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>